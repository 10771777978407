<template>
    <div v-if="loading" class="loadingModal" >
        <el-progress
            :type="type"
            :style="{top: '35%'}"
            :colors="colors"
            :percentage="percentage"
            :status="percentage === 100 ? 'success':undefined"
        />
    </div>
</template>
<script>
export default {
    name:'page-progress',
    model: {
        prop: 'loading',
        event: 'change'
    },
    props: {
        type: {type: String,default: 'circle'},
        loading: {type: Boolean, default: false},
        percentage: {type: Number,default:0},
    },
    data(){
        return {
            colors: [
                {color: '#f56c6c', percentage: 20},
                {color: '#e6a23c', percentage: 40},
                {color: '#5cb87a', percentage: 60},
                {color: '#1989fa', percentage: 80},
                {color: '#6f7ad3', percentage: 100}
            ]
        }
    },
    watch: {
        loading(val) {
            this.$emit('change', val);
        }
    }
};
</script>
<style lang="scss">
.loadingModal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    text-align: center;
    background: rgba(255, 255, 255, 0.9);
    .el-progress-circle{
        svg{
            fill: #fff;
        }
    }
}
</style>